<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>采购管理</el-breadcrumb-item>
      <el-breadcrumb-item>对公采购审批</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">

      <div class="top-box">
          <div class="tops">
          <span>审核人：</span>
          <el-select v-model="auth_userss" placeholder="未选择">
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="shaishen(item.value)"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>状态：</span>
          <el-select v-model="auth_status" placeholder="未选择">
            <el-option v-for="item in zhuang" :key="item.value" :label="item.name" :value="item.value" @click.native="zhuangtai(item.value)"></el-option>
          </el-select>
        </div>
      </div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column sortable prop="date" label="日期" width="180"></el-table-column>
        <el-table-column prop="apply_user" label="采购申请人" width="180"></el-table-column>
        <el-table-column prop="goods_info" label="采购物品" width="180"></el-table-column>
        <el-table-column prop="auth_user_name" label="采购审核人" width="180"></el-table-column>
        <el-table-column prop="house_name" label="入库仓库" width="180"></el-table-column>
        <el-table-column prop="auth_status_desc" label="审核状态" width="180"></el-table-column>
        <el-table-column label="操作" width="220px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-view" @click="look(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 详情 -->
    <el-dialog title="对公采购审批" :visible.sync="XiangVisible" :before-close="xiangClose" width="60%" center>
      <!-- {{ purchase_user }} -- {{ company }} -->
      <div class="tan">
        <div class="let">申请人</div>
        <div class="el-select"><el-input v-model="apply_user.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">采购公司</div>
        <div class="el-select"><el-input v-model="companyss.name" disabled></el-input></div>
        <el-button type="primary" size="small" class="btn" @click="setgong">变更采购公司</el-button>
      </div>

      <div class="tan">
        <div class="let">采购人</div>
        <div class="el-select"><el-input v-model="purchase_userss.name" disabled></el-input></div>
        <el-button type="primary" size="small" class="btn" @click="setren">变更采购人</el-button>
      </div>

      <div class="tan">
        <div class="let">入库仓库</div>
        <div class="el-select"><el-input v-model="house.name" disabled></el-input></div>
      </div>

      <div class="tan">
        <div class="let">采购审批人</div>
        <div class="el-select"><el-input v-model="auth_user.name" disabled></el-input></div>
      </div>

      <div class="huolist">
        <el-table :data="items" stripe style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="goods_name" label="货物名称"></el-table-column>
          <el-table-column prop="goods_no" label="货物编号"></el-table-column>
          <el-table-column prop="goods_type" label="货物分类"></el-table-column>
          <el-table-column prop="goods_desc" label="货物描述"></el-table-column>
          <el-table-column prop="supplier_name" label="供应商名称"></el-table-column>
          <el-table-column prop="supplier_goods_no" label="供应商货物编号"></el-table-column>
          <el-table-column prop="apply_num" label="数量"></el-table-column>
          <el-table-column prop="unit" label="单位"></el-table-column>
          <el-table-column prop="item_remark" label="备注"></el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="tongss">通过</el-button>
        <el-button type="danger" @click="jujue">拒绝</el-button>
      </span>

      <!-- 变更采购公司 -->
      <el-dialog width="30%" title="变更采购公司" :visible.sync="biangong" :before-close="biangongClose" append-to-body center>
        <div class="tan">
          <div class="let">原采购公司</div>
          <div class="el-select"><el-input v-model="companyss.name" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">变更采购公司</div>
          <div class="el-select">
            <el-select v-model="company.value" placeholder="未选择" clearable>
              <el-option v-for="item in canggongsi" :key="item.value" :label="item.name" :value="item.value" @click.native="gongsibian(item)"></el-option>
            </el-select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="gongquxiao">取 消</el-button>
          <el-button type="primary" @click="gongqueding">确定</el-button>
        </span>
      </el-dialog>

      <!-- 变更采购人 -->
      <el-dialog width="30%" title="变更采购人" :visible.sync="bianren" :before-close="bianrenClose" append-to-body>
        <div class="tan">
          <div class="let">原采购人</div>
          <div class="el-select"><el-input v-model="purchase_userss.name" disabled></el-input></div>
        </div>
        <div class="tan">
          <div class="let">变更采购人</div>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入内容"
            v-model="purchase_user.name"
            :fetch-suggestions="caivalidateCounts"
            value-key="name"
            @select="caihandleSelect($event, '采购人')"
          ></el-autocomplete>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="renquxiao">取 消</el-button>
          <el-button type="primary" @click="renqueding">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>

    <!--  不通过弹窗 -->
    <el-dialog title="审核" :visible.sync="noshen" width="40%" :before-close="shennotongClose">
      <div class="tan">
        <div class="let">审核备注</div>
        <div class="el-select"><el-input v-model="remarkss" placeholder="请输入审核备注"></el-input></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="notongquxiao()">取 消</el-button>
        <el-button type="primary" @click="noaddgoto()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 审核通过 -->
    <el-dialog title="审核" :visible.sync="loshenss" width="40%" :before-close="shentongClose">
      <div class="tan">
        <div class="let">审核备注</div>
        <div class="el-select"><el-input v-model="remark" placeholder="请输入审核备注"></el-input></div>
      </div>

      <div class="tan">
        <div class="let">下一个审核人</div>
        <div class="el-select">
          <el-select v-model="next_user" placeholder="未选择" clearable>
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="tongqu()">取 消</el-button>
        <el-button type="primary" @click="addgoto()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import Fenye from '../../../components/Fenye.vue'
import { Shenqinglist, Shencaihe, Shenheren, Shenxiang, Canggongsi } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      auth_userss:'',
      noshen: false,            // 审核不通过弹窗
      loshenss: false,          // 通过审核弹窗
      id: '',                   // 审核记录id
      status: '',               // 审核状态 1-通过 2-不通过 *
      remark: '',               // 审核备注
      remarkss: '',             // 审核不通过备注
      next_user: '',            // 下一步审核人ID
      purchase_user: {
        value: '',
        is_change: '0',
        from: '',
        name: ''
      },                        // 采购人变更后需要传给后端的数据
      company: {
        value: '',
        is_change: '0',
        from: '',
        name: ''
      },                        // 采购公司变更后需要传给后端的数据
      xiangqing: {},
      apply_user: {},           // 详情申请人
      auth_user: {},            // 详情审核人
      purchase_userss: {},      // 详情采购人
      companyss: {},            // 详情采购公司
      house: {},                // 入库仓库
      XiangVisible: false,      // 详情弹窗
      biangong: false,          // 变更采购公司弹窗
      bianren: false,           // 变更采购人弹窗
      complete_percent: '',     // 详情采购完成进度
      items: [],                // 详情采购明细
      auth_list: [],            // 详情审批表格
      canggongsi: [],           // 仓库采购公司列表
      caigoulist: [],           // 采购人列表
      renlist: [],
      auth_status: 3,
      tableData: [],            // 列表数据
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
        zhuang: [
        { name: '全部', value: -1 },
        { name: '待处理', value: 0 },
        { name: '已通过', value: 1 },
        { name: '未通过', value: 2 },
        { name: '审批中', value: 3 },
        { name: '取消', value: 4 }
      ]
    }
  },
  mounted() {
    this.Shenqinglists()
    this.Canggongsilist()
    this.Getrenlist() // 人员列表
  },
  methods: {
    // 审核不通过取消
    notongquxiao() {
      this.remarkss = ''
      this.noshen = false
    },

    // 确认不通过
    noaddgoto() {
      this.Shenhenotong()
    },

    // 确认通过审核、
    addgoto() {
      this.Shenhetong()
    },

        // 筛选状态
    zhuangtai(e) {
      this.Shenqinglists()
    },

    // 通过取消
    tongqu() {
      this.loshenss = false
      this.next_user = ''
      this.remark = ''
      // console.log('通过取消')
    },

    // 审核通过
    tongss() {
      this.loshenss = true
      this.status = 1
    },

    // 拒绝
    jujue() {
      // this.XiangVisible = false
      this.noshen = true
      this.company.value = ''
      this.company.name = ''
      this.company.is_change = 0
      this.purchase_user.value = ''
      this.purchase_user.name = ''
      this.purchase_user.is_change = 0
      this.status = 2
    },

    // 变更公司确定
    gongqueding() {
      // console.log('确定', this.company)
      this.companyss.name = this.company.name
      this.biangong = false
    },

    // 变更采购人确定
    renqueding() {
      // console.log('确定', this.purchase_user)
      this.purchase_userss.name = this.purchase_user.name
      this.bianren = false
    },

    biangongClose() {
      this.gongquxiao()
    },

    bianrenClose() {
      this.renquxiao()
    },

    shentongClose() {
      this.tongqu()
    },

    shennotongClose() {
      this.notongquxiao()
    },

    // 变更公司取消
    gongquxiao() {
      this.biangong = false
      this.company.value = ''
      this.company.name = ''
      this.company.is_change = 0
    },

    // 变更采购人取消
    renquxiao() {
      this.bianren = false
      this.purchase_user.value = ''
      this.purchase_user.name = ''
      this.purchase_user.is_change = 0
    },

    // 变更公司选中
    gongsibian(e) {
      // console.log(e)
      this.company.name = e.name
      this.company.is_change = 1
    },

    // 变更采购公司
    setgong() {
      // console.log('变更采购公司')
      this.biangong = true
    },

    // 变更采购人
    setren() {
      // console.log('变更采购人')
      this.bianren = true
    },

    // 搜索负责人后的数据
    caivalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 选中采购人
    caihandleSelect(e, val) {
      // console.log(e, val)

      this.purchase_user.value = e.value
      this.purchase_user.name = e.name
      this.purchase_user.is_change = 1
    },

    // 取消
    xiangClose() {
      // this.jujue()
      this.XiangVisible = false
      this.company.value = ''
      this.company.name = ''
      this.company.is_change = 0
      this.purchase_user.value = ''
      this.purchase_user.name = ''
      this.purchase_user.is_change = 0
      this.status = 2
    },

    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false
    },

    // 查看
    look(id) {
      // console.log(id)
      this.id = id
      this.XiangVisible = true
      this.Shenxiangqing(id)
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Shenqinglists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Shenqinglists()
    },

    // 列表
    async Shenqinglists() {
      const { data } = await Shenqinglist({ ...this.pageData, auth_status: this.auth_status ,auth_user: this.auth_userss})
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 审核
    async Shenhetong() {
      const { data } = await Shencaihe({
        id: this.id,
        status: this.status,
        remark: this.remark,
        next_user: this.next_user,
        company: this.company,
        purchase_user: this.purchase_user
      })
      // console.log(data)
      this.getanpostfun(data, '审核通过', this.Shenqinglists(), this.shentongClose(), this.xiangClose(), this.tongqu())
      this.loshenss = false
      this.XiangVisible = false
    },

    // 审核不通过
    async Shenhenotong() {
      const { data } = await Shencaihe({ id: this.id, status: this.status, remark: this.remarkss })
      // console.log(data)
      this.getanpostfun(data, '审核不通过', this.Shenqinglists(), this.shennotongClose(), this.xiangClose(), this.noshen = false, this.XiangVisible = false)
    },

    // 人员列表
    async Getrenlist() {
      const { data } = await Shenheren()
      this.renlist = data.data
    },

    // 申请采购单详情
    async Shenxiangqing(id) {
      const { data } = await Shenxiang({ id })
      this.xiangqing = data.data
      // console.log(this.xiangqing)
      this.apply_user = data.data.apply_user // 详情申请人
      this.auth_user = data.data.auth_user // 详情审核人
      this.purchase_userss = data.data.purchase_user // 详情采购人
      this.companyss = data.data.company // 详情采购公司
      this.company.from = data.data.company.value // 详情采购公司没改变之前的值
      this.purchase_user.from = data.data.purchase_user.value // 详情采购人没改变之前的值
      this.house = data.data.house // 入库仓库
      this.complete_percent = data.data.complete_percent // 详情采购完成进度
      this.items = data.data.items // 详情采购明细
      this.auth_list = data.data.auth_list // 详情审批表格
    },

    // 仓库采购公司列表
    async Canggongsilist(v) {
      const { data } = await Canggongsi()
      // console.log(data);
      this.canggongsi = data.data
    },

    // 采购人
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>

<style  scoped>
.top {
  display: flex;
  align-items: center;
}

.tan {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.tans {
  text-align: right;
}
.let {
  width: 90px;
  margin-left: 15px;
  margin-right: 10px;
}
.huolist {
  width: 100%;
}
.biaoti {
  margin-top: 20px;
  font-size: 15px;
  color: red;
}
.btn {
  margin-left: 15px;
  width: 104px;
  text-align: center;
}
.el-button {
  margin-right: 50px;
}
.top-box{
  display: flex;
  align-items: center;
}
.tops{
  margin-left: 15px;
}
</style>
